$scheduler-bg-color: #f8f9fa;
$sidebar-bg-color: #ffffff;
$sidebar-width: 280px;
$active-color: #6cc8d3; // New color for active elements
$header-height: 60px;
$text-color: #333;
$icon-margin: 8px;

.scheduler-container {
  display: flex;
  height: 100vh;
  width: 100vw; // Ensure full viewport width

  .sidebar {
    background-color: $sidebar-bg-color;
    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    height: 100vh;
    nav {
      width: 100%;
      height: 100vh;
      ul {
        list-style: none;
        padding: 0;
        height: 100%;

        li {
          margin: 10px 0;
          margin-bottom: 3vh;

          a {
            text-decoration: none;
            color: $text-color;
            font-size: 18px;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border-radius: 4px;

            i {
              margin-right: $icon-margin;
            }

            &:hover,
            &.active {
              background-color: $active-color;
              color: #fff;

              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .main-content {
    flex-grow: 1;
    background-color: $scheduler-bg-color;
    display: flex;
    flex-direction: column;
    height: 100vh; // Ensure full height

    .header {
      background-color: $sidebar-bg-color;
      height: $header-height;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;

      .top-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%; // Ensure it takes full width
        .logo-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%; /* Ensure it takes full width of the sidebar */
        }

        .logo-image {
          width: 60px; /* Adjust the size as needed */
          height: auto;
          margin-left: 200px; /* Space between the image and the text */
        }

        .logo-text {
          font-size: 24px;
          color: #555;
          font-weight: bold;
        }

        .logo-highlight {
          color: #00c0c0; // Change to your desired highlight color
        }

        ul {
          list-style: none;
          display: flex;
          padding: 0;
          margin: 0;

          li {
            margin-right: 60px; // Increased space between items

            a {
              text-decoration: none;
              color: $text-color;
              font-size: 18px;

              &:hover {
                color: $active-color;
              }
            }
          }
        }

        .user-info {
          display: flex;
          align-items: center;
          color: $text-color;
          margin-left: auto; // Push to the far right

          .language-icon {
            margin-right: 10px;
          }

          .user-details {
            display: flex;
            align-items: center;

            .user-icon {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
            }

            .user-text {
              display: flex;
              flex-direction: column; // Stack text vertically
              align-items: center; // Center the text

              span {
                font-size: 16px;
                color: $text-color;
                line-height: 1.2; // Adjust line height for better spacing

                &:last-child {
                  font-size: 12px; // Smaller font size for Admin
                  color: $text-color;
                  margin-top: 2px; // Small margin to separate from the name
                }
              }
            }
          }
        }
      }
    }

    .content {
      position: absolute;
      height: 80%; // Adjust height
      width: 100%; // Ensure full width minus sidebar

      .calendar-container {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        height: 80%; // Take full height
        width: 205%; // Take full width
        display: flex;
        flex-direction: column; // Allow calendar to take full height
        // margin-left: -60%; // Move it to the left as much as possible

        .calendar-legend {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 10px;

          .legend-item {
            display: inline-block;
            margin-right: 20px;
            padding: 5px 10px;
            border-radius: 4px;
            font-weight: bold;

            &.post {
              background-color: #007bff;
              color: #fff;
            }

            &.reel {
              background-color: #28a745;
              color: #fff;
            }

            &.story {
              background-color: #6f42c1;
              color: #fff;
            }
            &.pending {
              background-color: #f0e68c;
              color: '#333'
            }
            // &.pending {
            //   background-color: #f0ad4e; /* orange-ish */
            // }
            
            &.scheduled {
              background-color: #5bc0de; /* light-blue */
            }
            
            &.posted {
              background-color: #5cb85c; /* green */
            }
            
            &.scripted {
              background-color: #d9534f; /* red */
            }
          }
        }
        .event-item {
          padding: 0px 10px;
          border-radius: 4px;
          color: #fff;
          font-weight: bold;
          cursor: pointer; // Add cursor change for interactivity
        
          &.post {
            background-color: #007bff;
          }
        
          &.reel {
            background-color: #28a745;
          }
        
          &.story {
            background-color: #6f42c1;
          }

          &.pending {
            background-color: #f0e68c;
          }
          &.scheduled {
            background-color: #5bc0de; /* light-blue */
          }
          
          &.posted {
            background-color: #5cb85c; /* green */
          }
          
          &.scripted {
            background-color: #d9534f; /* red */
          }
        }

        .rbc-toolbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .rbc-toolbar-label {
            font-size: 18px; // Adjust font size as needed
            font-weight: bold; // Make it bold
            color: $text-color; // Set color
            margin: 0 10px; // Adjust margin as needed
          }

          .rbc-btn-group {
            display: flex;
            background-color: #ffffff; // White background for the button container
            border-radius: 8px; // Rounded corners
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional shadow for depth
            overflow: hidden; // Ensure buttons don't overflow the container

            button {
              background-color: #ffffff; // Default background color
              color: #000; // Default text color
              border: none;
              padding: 5px 20px; // Adjust padding for button size
              margin: 0;
              border-right: 1px solid #ddd; // Separator between buttons
              &:last-child {
                border-right: none; // Remove the border from the last button
              }

              &:hover {
                background-color: #f0f0f0; // Light background color on hover
              }

              &:active,
              &:focus,
              &.rbc-active {
                background-color: #4f8ef7; // Blue color when active or focused
                color: #fff;
              }
            }
          }
        }

        .rbc-month-view {
          border: none;
          flex-grow: 1; // Allow the calendar to grow
        }

        .rbc-day-bg {
          border: none;
        }

        .rbc-event {
          background-color: white;
          color: white;
          border-radius: 4px;
          padding: 5px;
          &.post {
            background-color: #007bff;
          }

          &.reel {
            background-color: #28a745;
          }

          &.story {
            background-color: #6f42c1;
          }
        }
        .event-item {
          position: relative;
          display: inline-block;
          padding-right: 30px;
          width: 100%;
          background-color: white;
          color: white;
          border-radius: 4px;
          padding: 5px;
          &.post {
            background-color: #007bff;
          }

          &.reel {
            background-color: #28a745;
          }

          &.story {
            background-color: #6f42c1;
          }
        }

        .delete-icon {
          display: none;
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          color: red;
          font-size: 18px;
        }

        .event-item:hover .delete-icon {
          display: inline;
        }

        .event-preview {
          background-color: white;
          border: 1px solid #ccc;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          padding: 10px;
          z-index: 1000;
          width: 200px;
          left: -50px;
          pointer-events: none; /* Prevent interactions with the preview itself */
        }

        .event-thumbnail {
          width: 100%;
          height: auto;
          margin-bottom: 10px;
        }

        .event-preview-details {
          text-align: center;
        }

        .event-preview-time {
          color: gray;
          font-size: 0.85em;
        }
      }

      .write-post-container {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        margin-top: 20px;
        background-color: #f9f9f9;
        height: 100%; // Take full height
        width: 205%; // Take full width
        flex-direction: row; // Adjusted to row for horizontal alignment
        // margin-left: -60%; // Move it to the left as much as possible

        .error-message {
          color: red;
          font-size: 0.9rem;
          margin-top: 5px;
          margin-left: 5px;
        }

        .post-preview {
          flex: 1;
          margin-right: 20px;
        }

        .instagram-post {
          width: 320px;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .post-header {
          display: flex;
          align-items: center;
          padding: 10px;
        }

        .profile-pic {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .post-user-info {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
        }

        .username {
          font-weight: bold;
        }

        .location {
          font-size: 0.9em;
          color: #888;
        }

        .post-image {
          width: 100%;
          height: 320px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f0f0f0;
          position: relative;
        }

        .image-upload-input {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }

        .post-image img {
          max-width: 100%;
          max-height: 100%;
        }

        .post-footer {
          padding: 10px;
        }

        .post-interactions {
          display: flex;
          justify-content: space-between;
          font-size: 1.2em;
          margin-bottom: 5px;
        }

        .post-caption {
          margin-bottom: 5px;
        }

        .bold-text {
          font-weight: bold;
        }

        .post-hashtags {
          color: #3897f0;
        }

        .post-comments {
          color: #888;
          font-size: 0.9em;
        }

        .btn-schedule {
          display: inline-block; // Ensure buttons are inline-block
          width: auto; // Adjusted to auto to fit the content
          padding: 8px 15px; // Adjusted padding for better spacing
          border: none;
          border-radius: 5px;
          font-size: 1em;
          cursor: pointer;
          text-align: center; // Center-align text
          background-color: #3897f0;
          color: white;
          margin-right: 10px; // Add space between buttons
        }

        .btn-save-draft {
          display: inline-block; // Ensure buttons are inline-block
          width: auto; // Adjusted to auto to fit the content
          padding: 8px 15px; // Adjusted padding for better spacing
          border: none;
          border-radius: 5px;
          font-size: 1em;
          cursor: pointer;
          text-align: center; // Center-align text
          background-color: #888;
          color: white;
        }

        .button-group {
          display: flex; // Use flexbox to align buttons
          justify-content: center; // Center the buttons
          margin-top: 20px; // Add some space above the button group
          width: 60%; // Ensure the button group takes the full width
        }

        .post-options {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 20px;
          flex: 2;
          margin-bottom: 200px;
        }

        .input-group {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          width: 70%;
        }

        .input-group.label-top label {
          margin-bottom: 0px;
        }

        .input-group input,
        .input-group select {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 100%;
        }

        .date-time-group {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          width: 50%;
          gap: 60px;
          margin-left: 0px;
        }

        .date-time-group .date-picker,
        .date-time-group .time-picker {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
        }
        .add-campaign-btn {
          background-color: #007bff;
          border: none;
          color: white;
          padding: 0; // Remove padding to control the size better
          border-radius: 50%; // Make it circular
          cursor: pointer;
          margin-left: 10px;
          width: 30px; // Set fixed width
          height: 30px; // Set fixed height (same as width to keep it square)
          display: flex; // Flexbox for centering the icon
          justify-content: center; // Center the content horizontally
          align-items: center; // Center the content vertically
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); // Optional: Add some shadow for better visibility

          &:hover {
            background-color: #0056b3; // Darken on hover
          }

          .fa-plus {
            font-size: 16px; // Adjust icon size for better fit
          }
        }

        .campaign-select {
          display: flex;
          align-items: center; // Aligns the button and select element horizontally
        }
      }

      .reel-options,
      .reel-settings {
        width: 100%;
      }

      .input-group {
        margin-bottom: 12px;
        label {
          display: block;
          margin-bottom: 10px;
          font-size: 18px;
        }

        select,
        input,
        textarea {
          width: 100%;
          padding: 15px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 15px;
        }

        textarea {
          resize: none;
        }
      }
    }
  }
}

.sidebar {
  width: 200px; // Adjust as necessary
  background-color: #f4f4f4;
  padding: 20px;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
}

.sidebar nav ul li {
  margin: 10px 0;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.sidebar nav ul li a.active {
  color: #00c0c0;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.header {
  background-color: #f4f4f4;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.top-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.top-nav ul li {
  margin: 0 10px;
}

.top-nav ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.user-info {
  display: flex;
  align-items: center;
}

.language-icon {
  margin-right: 10px;
}

.user-details {
  display: flex;
  align-items: center;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-text span {
  display: block;
  text-align: left;
}

.user-text span:first-child {
  font-size: 14px;
  font-weight: bold;
}

.user-text span:last-child {
  font-size: 12px;
  color: #888;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  width: 500px;
  text-align: center;
  z-index: 1000;
}

.modal h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.modal p {
  margin-bottom: 20px;
  font-size: 18px;
}

.modal button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal button:hover {
  background-color: #0056b3;
}

.modal button.cancel {
  background-color: #6c757d;
}

.modal button.cancel:hover {
  background-color: #5a6268;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.infinity-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.infinity {
  width: 80px;
  height: 80px;
  position: relative;
}

.infinity::before,
.infinity::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 8px solid transparent;
  border-top-color: #555;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

.infinity::after {
  border-top-color: #00c0c0;
  animation-delay: 0.5s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 60px;
  aspect-ratio: 2;
  --outer-color: #555555; /* Gray color for outer balls */
  --inner-color: #4cc1c4; /* Blue color for middle ball */
  --_g: no-repeat
    radial-gradient(circle closest-side, var(--outer-color) 90%, #0000);
  --_g-inner: no-repeat
    radial-gradient(circle closest-side, var(--inner-color) 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g-inner) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: 160px;
}

body {
  background: #f7f6f2; /* Or another light color like #ffffff */
}

.campaign-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  width: 500px;
  text-align: center;
  z-index: 1000;
}

.campaign-modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.campaign-modal-content p {
  margin-bottom: 20px;
  font-size: 18px;
}

.campaign-modal-content button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.campaign-modal-content button:hover {
  background-color: #0056b3;
}

.campaign-modal-content button.cancel {
  background-color: #6c757d;
}

.campaign-modal-content button.cancel:hover {
  background-color: #5a6268;
}

.campaign-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}
.campaign-modal-content textarea {
  width: 100%; // Make the textarea take the full width of the modal
  height: 100px; // Adjust the height to make it larger
  padding: 10px; // Add some padding inside the textarea for better readability
  border: 1px solid #ccc; // Add a light border for better visibility
  border-radius: 5px; // Add some rounding for a softer look
  font-size: 16px; // Increase the font size for better readability
  resize: vertical; // Allow resizing only in the vertical direction
}

.delete-modal-content {
  margin-top: 3.6vw;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.delete-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.delete-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn-confirm-delete {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-cancel-delete {
  background-color: gray;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.add-campaign-btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0; // Remove padding to control the size better
  border-radius: 50%; // Make it circular
  cursor: pointer;
  margin-left: 10px;
  width: 25px; // Set fixed width
  height: 25px; // Set fixed height (same as width to keep it square)
  display: flex; // Flexbox for centering the icon
  justify-content: center; // Center the content horizontally
  align-items: center; // Center the content vertically
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); // Optional: Add some shadow for better visibility

  &:hover {
    background-color: #0056b3; // Darken on hover
  }

  .fa-plus {
    font-size: 16px; // Adjust icon size for better fit
  }
}